import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  BoxProps,
  Button,
  HStack,
  Stack,
  Tag,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

import { TDatoCmsFinancingOption } from '../../../../../types/datocms';
import FinancialGetMoreInfoForm from '../../../../FinancialGetMoreInfoForm';
import { usePageContext } from '../../../../../context/PageContext';
import { getLinkByPageName } from '../../../../../lib/links';

type FinancingOptionsCardProps = BoxProps & {
  country?: string;
  financingOption: TDatoCmsFinancingOption;
};

const FinancingOptionsCard = (
  props: FinancingOptionsCardProps
): JSX.Element => {
  const { country, financingOption, ...boxProps } = props;
  const { t } = useTranslation(['financial-get-more-info-form', 'menu']);
  const { campusCode } = usePageContext();
  const { isOpen, onToggle } = useDisclosure();
  const applyPageUrl = `${getLinkByPageName('allCourses/application')}?fo=${
    financingOption.salesforceId
  }`;

  return (
    <Box
      borderRadius="card"
      boxShadow={financingOption.promotedTag ? 'active3' : 'active1'}
      h="min-content"
      p={3}
      {...boxProps}
    >
      <HStack spacing={2}>
        <Text lineHeight="150%" textStyle="body1">
          {financingOption.title}
        </Text>
        {financingOption.promotedTag && (
          <Tag variant="financingOption">{financingOption.promotedTag}</Tag>
        )}
      </HStack>
      <Text
        color="darkBlue.64"
        dangerouslySetInnerHTML={{ __html: financingOption.shortDescription }}
        mt={[1, null, null, 1.5]}
        textStyle="body4"
      />
      <Text mt={[1, null, null, 3]} textStyle="title2">
        {financingOption.highlight}
      </Text>
      <Stack
        direction={['column', null, null, 'row']}
        mt={[3, null, null, 4]}
        spacing={[1, null, null, 2]}
      >
        <Button
          colorScheme="secondary"
          h="4.8rem"
          maxW={[null, null, null, '25rem']}
          onClick={onToggle}
        >
          {t(
            'financial-get-more-info-form:FinancialGetMoreInfoForm.button.label'
          )}
        </Button>
        <Button
          colorScheme="secondary"
          h="4.8rem"
          maxW={[null, null, null, '25rem']}
          onClick={(): void => {
            window.location.href = applyPageUrl;
          }}
          variant="outline"
        >
          {t('menu:menu.cta')}
        </Button>
      </Stack>
      {isOpen && (
        <FinancialGetMoreInfoForm
          campus={campusCode}
          country={country}
          formClass="financial-get-more-info-campus-course-page"
          optionTitle={financingOption.title}
        />
      )}
    </Box>
  );
};

export default FinancingOptionsCard;
