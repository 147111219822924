import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const PaymentOptionsIcon = createIcon({
  displayName: 'PaymentOptionsIcon',
  path: (
    <>
      <circle cx="53" cy="53" fill="#EEEDFF" r="53" />
      <path
        clipRule="evenodd"
        d="M70.8 32.1a5.6 5.6 0 1 0 0-11.1 5.6 5.6 0 0 0 0 11.1Z"
        fill="url(#payment-options-icon-a)"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M35.3 81.8a5.6 5.6 0 1 0 0-11.1 5.6 5.6 0 0 0 0 11.1Z"
        fill="url(#payment-options-icon-b)"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M33 63.2A19.7 19.7 0 1 0 52.7 29 19.7 19.7 0 0 0 33 63.2Z"
        fill="url(#payment-options-icon-c)"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M56.2 76.2A19.7 19.7 0 1 0 75.9 42a19.7 19.7 0 0 0-19.7 34.2Z"
        fill="url(#payment-options-icon-d)"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M61.6 39.9h-.2a18.5 18.5 0 0 0-13 9.4 20.7 20.7 0 0 0-1 16 20 20 0 0 0 12.8-9.5c2.9-5 3-10.8 1.4-15.9Z"
        fill="url(#payment-options-icon-e)"
        fillRule="evenodd"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="payment-options-icon-a"
          x1="59"
          x2="64.5"
          y1="23.8"
          y2="36.4"
        >
          <stop stopColor="#72A7FF" />
          <stop offset="1" stopColor="#B8BCFF" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="payment-options-icon-b"
          x1="23.5"
          x2="29"
          y1="73.4"
          y2="85.8"
        >
          <stop stopColor="#FA69A8" />
          <stop offset="1" stopColor="#F27471" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="payment-options-icon-c"
          x1="30.3"
          x2="21.9"
          y1="41.2"
          y2="63.5"
        >
          <stop stopColor="#B8BCFF" />
          <stop offset="1" stopColor="#32C3FF" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="payment-options-icon-d"
          x1="56.2"
          x2="54.2"
          y1="48.4"
          y2="79.2"
        >
          <stop stopColor="#FFA478" />
          <stop offset="1" stopColor="#FB67AE" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="payment-options-icon-e"
          x1="55.7"
          x2="41.9"
          y1="37.4"
          y2="61.2"
        >
          <stop stopColor="#6565EE" />
          <stop offset="1" stopColor="#91A9FF" />
        </linearGradient>
      </defs>
    </>
  ),
  viewBox: '0 0 106 106',
});

export default PaymentOptionsIcon;
