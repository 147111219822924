import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const ReactIcon = createIcon({
  displayName: 'ReactIcon',
  path: (
    <g fill="#61DAFB">
      <path d="M493 220c0-32-40-63-103-82 15-64 8-115-20-131-6-4-14-5-22-5v22c4 0 8 1 11 3 14 7 20 37 15 75l-5 30c-20-5-41-9-64-11-13-19-27-36-41-50 32-31 63-47 84-47V2c-28 0-64 19-100 53-37-34-73-53-100-53v22c21 0 51 17 84 47-14 15-28 31-41 50-23 2-44 6-64 11l-5-29c-5-38 1-68 14-76 3-2 7-3 12-3V2c-8 0-16 2-23 6-28 16-34 66-20 130C43 157 3 188 3 220c0 33 40 63 103 82-15 64-8 115 20 131 7 4 14 5 23 5 27 0 63-19 99-53 37 34 73 53 100 53 9 0 16-2 23-6 28-16 34-66 20-130 62-19 102-50 102-82zm-130-67-13 40a473 473 0 0 0-28-48l41 8zm-46 107c-7 13-15 26-24 38a520 520 0 0 1-90 0 551 551 0 0 1-45-78 521 521 0 0 1 45-78 520 520 0 0 1 90 0 551 551 0 0 1 45 78c-6 13-13 27-21 40zm33-13c5 13 10 27 13 40l-41 8a552 552 0 0 0 28-48zM248 354l-28-32a619 619 0 0 0 56 0l-28 32zm-74-59-41-8 13-40a473 473 0 0 0 28 48zm74-208 28 32a619 619 0 0 0-56 0c9-12 19-23 28-32zm-74 58a552 552 0 0 0-28 48c-5-13-10-27-13-40l41-8zM83 271c-35-15-58-35-58-51s23-36 58-51l28-10c6 20 13 40 22 61-9 21-16 41-22 61l-28-10zm54 143c-14-8-19-38-15-76l5-30c20 5 41 9 64 11 13 19 27 36 41 50-32 31-63 47-84 47l-11-2zm237-77c5 39-1 68-14 76-3 2-7 3-12 3-21 0-51-17-84-47 14-15 28-31 41-50 23-2 44-6 64-11l5 29zm39-66-28 10c-6-20-13-40-22-61 9-21 16-41 22-61l28 10c35 16 58 35 58 51s-23 36-58 51zM148 2z" />
      <circle cx="247.9" cy="220" r="45.7" />
      <path d="M347 2z" />
    </g>
  ),
  viewBox: '0 0 496 440',
});

export default ReactIcon;
