import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const Js = createIcon({
  displayName: 'JsIcon',
  viewBox: '0 0 27 30',
  path: (
    <>
      <path d="M2.2 26.9 0 0h26.2l-2.3 26.8-10.7 3-11-3Z" fill="#E9CA32" />
      <path d="M13.2 27.4V2.6H24l-2 22.2-8.8 2.6Z" fill="#FFDE25" />
      <path
        d="M12 5.5H9.2V22L7 21.6v-2.2L4.3 19v5l7.7 2V5.4Zm2.2 0H22l-.6 3.2h-4.3v5.2h4.3L21 24.3 14.2 26v-3.2l4.6-1.6.3-4.4-5 .7V5.5Z"
        fill="#fff"
      />
    </>
  ),
});

export default Js;
