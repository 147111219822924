import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const UserExperience = createIcon({
  displayName: 'UserExperience',
  path: (
    <>
      <path
        d="M1.5 1.5h20v30h-20z"
        stroke="url(#user-experience-icon-a)"
        strokeWidth="3"
      />
      <path
        d="M7.5 7.5h8v8h-8z"
        stroke="url(#user-experience-icon-b)"
        strokeWidth="3"
      />
      <path
        d="M6 22.5h11"
        stroke="url(#user-experience-icon-c)"
        strokeWidth="3"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="user-experience-icon-a"
          x1="11.5"
          x2="11.5"
          y1="0"
          y2="33"
        >
          <stop stopColor="#68E0D0" />
          <stop offset="1" stopColor="#30C7F9" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="user-experience-icon-b"
          x1="8.8"
          x2="8.2"
          y1="8.5"
          y2="17.1"
        >
          <stop stopColor="#FFA478" />
          <stop offset="1" stopColor="#FB67AE" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="user-experience-icon-c"
          x1="11.5"
          x2="11.5"
          y1="24"
          y2="25"
        >
          <stop stopColor="#68E0D0" />
          <stop offset="1" stopColor="#30C7F9" />
        </linearGradient>
      </defs>
    </>
  ),
  viewBox: '0 0 23 33',
});

export default UserExperience;
