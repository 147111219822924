import { updateDataLayer } from '../../lib/datalayer';

function emitApplyEvent(): void {
  updateDataLayer({
    event: 'gaEvent',
    eventCategory: 'interest',
    eventAction: 'click cta::apply',
    eventLabel: 'upcoming cohorts::apply now',
  });
}

function emitFormatEvent(format: string): void {
  updateDataLayer({
    event: 'gaEvent',
    eventCategory: 'interest',
    eventAction: 'click text',
    eventLabel: `change cohort format::${format}`,
  });
}

export { emitApplyEvent, emitFormatEvent };
