import styled, { css } from 'styled-components';
import { Link } from 'gatsby';

import config from '../config';

const Projects = styled.section`
  display: flex;
  justify-content: space-between;
`;

const Item = styled.article`
  position: relative;
  padding-bottom: 5rem;
  background-color: ${config.palette.white};
  border-radius: 0.6rem;
  display: flex;
  flex-direction: column;
  width: 45%;

  & > div {
    padding: 1.6rem;

    p {
      color: ${config.palette.darkBlueTransparent('0.6')};
    }
  }

  img {
    border-radius: 0.6rem 0.6rem 0 0;
    height: auto;
    width: 100%;
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    width: 100%;
  }
`;

const ProjectButton = styled.a`
  position: absolute;
  bottom: 0;
  background-color: ${config.palette.lightBlue};
  border-radius: inherit;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  color: ${config.palette.white};
  display: block;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.9rem;
  padding: 1.8rem 0;
  text-align: center;
  text-decoration: none;
  width: 100%;

  @media (max-width: ${config.resolutions.mobileL}) {
    display: none;
  }
`;

const Swipeable = styled.div`
  display: flex;
  padding: 2rem 0;

  svg:first-child {
    margin-right: 2.4rem;
  }

  svg {
    cursor: pointer;

    & > path {
      fill: white;
    }
  }
`;

const Pagination = styled.section`
  width: 100%;
  display: flex;
  padding: 2rem 0;
  margin-top: 2.4rem;
`;

const PaginationItems = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-right: 6rem;

  @media (max-width: ${config.resolutions.mobileL}) {
    margin-right: 2.8rem;
  }
`;

const PaginationIndex = styled('article')<{ totalItems: number }>`
  width: ${(props) => 100 / (props.totalItems / 2)}%;
  height: 0.2rem;
  margin-right: 0.4rem;
  background-color: ${config.palette.whiteTransparent('0.4')};

  &.active {
    background-color: ${config.palette.white};
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    width: ${(props) => 100 / props.totalItems}%;
  }
`;

const LayoutStudentProjects = css`
  background-color: ${config.palette.darkBlue};
  padding-top: 4.8rem;
  padding-bottom: 4.8rem;

  & > h3 {
    color: ${config.palette.white};
    margin-bottom: 4.8rem;
  }
`;

export {
  Projects,
  Item,
  ProjectButton,
  PaginationIndex,
  Swipeable,
  Pagination,
  PaginationItems,
  LayoutStudentProjects,
};
