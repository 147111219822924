import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const UserResearch = createIcon({
  displayName: 'UserResearch',
  path: (
    <>
      <path
        d="m19.3 18.5 11 10"
        stroke="url(#user-research-icon-a)"
        strokeWidth="4"
      />
      <circle
        cx="12"
        cy="12"
        r="10"
        stroke="url(#user-research-icon-b)"
        strokeWidth="4"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="user-research-icon-a"
          x1="20.6"
          x2="20.1"
          y1="22.7"
          y2="23.3"
        >
          <stop stopColor="#FFA478" />
          <stop offset="1" stopColor="#FB67AE" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="user-research-icon-b"
          x1="12"
          x2="12"
          y1="0"
          y2="24"
        >
          <stop stopColor="#68E0D0" />
          <stop offset="1" stopColor="#30C7F9" />
        </linearGradient>
      </defs>
    </>
  ),
  viewBox: '0 0 32 30',
});

export default UserResearch;
