import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const PreventionSystems = createIcon({
  displayName: 'PreventionSystems',
  path: (
    <>
      <path
        clipRule="evenodd"
        d="M30 15a15 15 0 0 0-30 0h6a9 9 0 0 1 18 0h6Z"
        fill="#F5A623"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M6 24h6v6h6v-6h6v-6h-6v-6h-6v6H6v6Z"
        fill="#36B2B2"
        fillRule="evenodd"
      />
    </>
  ),
  viewBox: '0 0 30 30',
});

export default PreventionSystems;
