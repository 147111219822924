import styled, { css } from 'styled-components';
import config from '../config';

const Timeline = styled.section`
  display: flex;
  overflow-x: scroll;
  margin-bottom: 4.8rem;
  position: relative;

  & > section {
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 auto;
    margin-top: 1.6rem;
    position: relative;

    & > article:nth-child(2) {
      margin-left: 0;
    }

    & > b {
      width: 100%;
    }

    &:before {
      background: linear-gradient(
        to right,
        rgba(89, 101, 132, 0.1) 0%,
        ${config.palette.darkBlue} 49.17%,
        rgba(89, 101, 132, 0.1) 100%
      );
      content: '';
      height: 0.1rem;
      position: absolute;
      top: 5.5rem;
      width: calc(100% - 43.2rem);
    }

    &:first-child {
      margin-left: calc((100% - 129.6rem) / 2);
    }
  }

  @media (max-width: ${config.resolutions.desktopS}) {
    & > section {
      &:first-child {
        margin-left: calc((100% - 112rem) / 2);
      }
    }
  }

  @media (max-width: ${config.resolutions.tablet}) {
    & > section {
      &:first-child {
        margin-left: calc((100% - 70.4rem) / 2);
      }
    }
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    margin-bottom: 3.2rem;

    & > section {
      &:before {
        width: calc(100% - 32.2rem);
      }

      &:first-child {
        margin-left: 2.5rem;
      }
    }
  }
`;

const Item = styled.article`
  margin-left: 6.4rem;
  margin-top: 2.4rem;
  padding-top: 2.4rem;
  width: 28.8rem;

  &.ft {
    &:nth-child(4n + 2) {
      div {
        border-color: #0d8b9f;
      }
    }
    &:nth-child(odd) {
      div {
        border-color: #abaeb7;
      }
    }
    &:nth-child(4n + 4) {
      div {
        border-color: #2dc5fa;
      }
    }
    &:nth-child(16) {
      div {
        border-color: #f49102;
      }
    }
  }
  &.pt {
    &:nth-child(2n + 2) {
      div {
        border-color: #0d8b9f;
      }
    }
    &:nth-child(4n + 3) {
      div {
        border-color: #2dc5fa;
      }
    }
    &:nth-child(4n + 5) {
      div {
        border-color: #abaeb7;
      }
    }
  }
  &.pt-weekday {
    &:nth-child(10) {
      div {
        border-color: #f49102;
      }
    }
  }
  & > p {
    color: ${config.palette.darkBlueTransparent('0.6')};
  }

  & > b {
    display: block;
    margin: 0.8rem 0;
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    margin-left: 4.8rem;
    width: 20rem;
  }
`;

const TimelineCircle = styled.div`
  background-color: ${config.palette.white};
  border: 0.4rem solid;
  border-radius: 50%;
  height: 1.6rem;
  position: absolute;
  top: 4.8rem;
  width: 1.6rem;
`;

const LayoutDayIronhack = css`
  margin-bottom: 3.2rem;

  & > span {
    color: ${config.palette.green};
  }

  & > p {
    color: ${config.palette.darkBlueTransparent('0.6')};
    margin-top: 1.6rem;
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    margin-bottom: 1.6rem;
  }
`;

export { Timeline, Item, TimelineCircle, LayoutDayIronhack };
