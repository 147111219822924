import styled, { css } from 'styled-components';

import config from '../config';

const Timeline = styled.section`
  position: relative;
  display: flex;
  overflow-x: scroll;
  margin-bottom: 4.8rem;

  & > section {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1.6rem;
    position: relative;
    flex: 0 0 auto;

    & > article:nth-child(2) {
      margin-left: 0;
    }

    & > b {
      width: 100%;
    }

    &:before {
      background: linear-gradient(
        to right,
        rgba(89, 101, 132, 0.1) 0%,
        ${config.palette.darkBlue} 49.17%,
        rgba(89, 101, 132, 0.1) 100%
      );
      content: '';
      height: 0.1rem;
      position: absolute;
      top: 5.5rem;
      width: calc(100% - 43.2rem);
    }
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    margin-bottom: 3.2rem;

    & > section {
      &:before {
        width: calc(100% - 32.2rem);
      }
    }
  }
`;

const Item = styled.article`
  margin-top: 2.4rem;
  padding-top: 2.4rem;
  width: 28.8rem;
  margin-right: 6.4rem;

  & > p {
    color: ${config.palette.darkBlueTransparent('0.6')};
  }

  & > b {
    display: block;
    margin: 0.8rem 0;
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    margin-left: 4.8rem;
    width: 20rem;
  }
`;

const TimelineCircle = styled.div`
  position: absolute;
  width: 1.6rem;
  height: 1.6rem;
  top: 4.8rem;
  background-color: ${config.palette.white};
  border: 0.4rem solid #0d8b9f;
  border-radius: 50%;
`;

const LayoutDayIronhack = css`
  margin-bottom: 3.2rem;

  & > span {
    color: ${config.palette.green};
  }

  & > p {
    color: ${config.palette.darkBlueTransparent('0.6')};
    margin-top: 1.6rem;
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    margin-bottom: 1.6rem;
  }
`;

export { Timeline, Item, TimelineCircle, LayoutDayIronhack };
