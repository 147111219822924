import React, { useState } from 'react';
import _ from 'lodash';
import uniqid from 'uniqid';
import classNames from 'classnames';
import { isMobile } from '../../lib/utils';
import Layout from '../atoms/layout';
import { Title1 } from '../atoms/headers';
import {
  Projects,
  Item,
  ProjectButton,
  Pagination,
  PaginationIndex,
  Swipeable,
  PaginationItems,
  LayoutStudentProjects
} from './styles';
import { Text3, Text5 } from '../atoms/body';
import { withTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import Img from 'gatsby-image';
import GetStudentProjectsImage from '../../lib/student-projects-images';
import { useCourses } from '../../hooks/datoCMS/models/datoCourses';
import * as gaEvents from './ga_events';

const StudentProjects: React.SFC<any> = (props: {
  course: string;
  t: TFunction;
  courseCode: string;
}) => {
  const { course, courseCode, t } = props;
  const [pagination, setPagination] = useState(0);
  const itemsPerPage = isMobile() ? 1 : 2;
  const studentProjects = Object.values(
    t(`${course}:${courseCode}.studentProjects.projects`, {
      returnObjects: true
    })
  );
  const datoCourse = _.get(useCourses(), courseCode, {});

  const paginationCreator = () => {
    const items = [];
    const length = isMobile()
      ? studentProjects.length
      : Math.ceil(studentProjects.length / 2);
    for (let i = 0; i < length; i++) {
      items.push(
        <PaginationIndex
          totalItems={studentProjects.length}
          key={uniqid()}
          className={classNames({
            active: i * itemsPerPage === pagination
          })}
        />
      );
    }
    return items;
  };

  const changeCard = (num: number) => {
    const next = pagination + num;
    if (next < 0) {
      setPagination(studentProjects.length - 1);
    } else if (next >= studentProjects.length) {
      setPagination(0);
    } else {
      setPagination(next);
    }
  };

  const getItem = (
    item: {
      name: string;
      title: string;
      description: string;
      link: string;
    },
    index: number
  ) => (
    <Item key={uniqid()}>
      <Img fluid={datoCourse.studentProjects[index]} alt={item.title} />
      <div>
        <Text3>{item.title}</Text3>
        <Text5 as="p">{item.description}</Text5>
      </div>
      <ProjectButton
        href={item.link}
        target="_blank"
        rel="noreferrer noopener"
        onClick={gaEvents.emitViewDetailsEvent}
      >
        {item.name}
      </ProjectButton>
    </Item>
  );

  const getProjects = (index: number) => {
    const articles = studentProjects.slice(index, index + itemsPerPage);
    return articles.map((project, index: number) => getItem(project, index));
  };

  const isCyberSecurityCourse = courseCode === 'cysec';

  return (
    !isCyberSecurityCourse && (
      <Layout extend={LayoutStudentProjects}>
        <Title1>{t(`${course}:${courseCode}.studentProjects.title`)}</Title1>
        <Projects>{getProjects(pagination)}</Projects>
      </Layout>
    )
  );
};

export default withTranslation()(StudentProjects);
