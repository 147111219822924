import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, BoxProps, Button, SimpleGrid, Text } from '@chakra-ui/react';

type Country = { isoCode: string; name: string };

type FinancingOptionCountrySelectorProps = BoxProps & {
  countries: Country[];
  onCountrySelected: () => string;
};

const selectedButtonStyleProps = {
  boxShadow: 'inset 0px 0px 0px 1px var(--chakra-colors-gray-200)',
  bg: 'darkBlue.100',
  color: 'white',
  _hover: {
    boxShadow: 'inset 0px 0px 0px 1px var(--chakra-colors-gray-200)',
    bg: 'darkBlue.100',
    color: 'white',
  },
};

const buttonStyleProps = {
  boxShadow: 'inset 0px 0px 0px 1px var(--chakra-colors-darkBlue-20)',
  color: 'darkBlue.100',
  _hover: {
    boxShadow: 'inset 0px 0px 0px 1px var(--chakra-colors-darkBlue-20)',
    color: 'darkBlue.100',
  },
};

const FinancingOptionCountrySelector = (
  props: FinancingOptionCountrySelectorProps
): JSX.Element => {
  const { countries, onCountrySelected, ...boxProps } = props;
  const { t } = useTranslation(['financing']);
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);

  const handleCountrySelected = (isoCode: string): void => {
    setSelectedCountry(isoCode);
    onCountrySelected(isoCode);
  };
  return (
    <Box {...boxProps}>
      <Text as="h4" textStyle="title2">
        {t('financing:financing.countryOptions.title')}
      </Text>
      <SimpleGrid columns={[1, null, 3]} mt={4} spacing={2} {...boxProps}>
        {countries.map(({ isoCode, name }) => {
          const buttonProps =
            isoCode === selectedCountry
              ? selectedButtonStyleProps
              : buttonStyleProps;
          return (
            <Button
              fontSize="1.8rem"
              fontWeight={400}
              h="5.7rem"
              key={isoCode}
              variant="outline"
              {...buttonProps}
              onClick={(): void => handleCountrySelected(isoCode)}
            >
              {name}
            </Button>
          );
        })}
      </SimpleGrid>
    </Box>
  );
};

export default FinancingOptionCountrySelector;
