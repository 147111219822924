import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const Figma = createIcon({
  displayName: 'Figma',
  path: (
    <>
      <path
        d="M50 300c28 0 50-22 50-50v-50H50a50 50 0 0 0 0 100z"
        fill="#0acf83"
      />
      <path
        d="M0 150c0-28 22-50 50-50h50v100H50c-28 0-50-22-50-50z"
        fill="#a259ff"
      />
      <path
        d="M0 50C0 22 22 0 50 0h50v100H50C22 100 0 78 0 50z"
        fill="#f24e1e"
      />
      <path d="M100 0h50a50 50 0 0 1 0 100h-50V0z" fill="#ff7262" />
      <path d="M200 150a50 50 0 1 1-100 0 50 50 0 0 1 100 0z" fill="#1abcfe" />
    </>
  ),
  viewBox: '0 0 200 300',
});

export default Figma;
