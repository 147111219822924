import styled, { css } from 'styled-components';

import config from '../../components/config';

const CampusInformation = styled.section`
  display: flex;
  padding: 4.8rem calc((100% - 1296px) / 2);
  padding-bottom: 1.6rem;
  margin-bottom: 3.2rem;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: ${config.palette.darkBlue};

  @media (max-width: ${config.resolutions.desktopS}) {
    padding: 6.4rem calc((100% - 1120px) / 2);
  }

  @media (max-width: ${config.resolutions.tablet}) {
    padding: 6.4rem calc((100% - 704px) / 2);
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    padding: 6.4rem 2.4rem;
  }

  h3 {
    width: 100%;
    margin-bottom: 4.8rem;
    color: ${config.palette.white};
  }

  article {
    width: calc(50% - 3.2rem);
    padding-right: 9.6rem;
    margin-bottom: 3.2rem;
    color: ${config.palette.greyTransparent('0.6')};
    font-size: 2rem;
    line-height: 160%;

    & > b {
      display: block;
      margin-bottom: 1.6rem;
      color: ${config.palette.grey};
    }
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    article {
      width: 100%;
      padding-right: 0;
      font-size: 1.6rem;
      line-height: 150%;
      margin-top: 1.2rem;
    }
  }
`;

const Item = styled.section`
  font-size: 2rem;
  line-height: 160%;
  color: ${config.palette.darkBlueTransparent('0.6')};

  & > b {
    display: block;
    margin-bottom: 1.6rem;
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    font-size: 1.6rem;
    line-height: 150%;

    & > b {
      margin-bottom: 0.8rem;
    }
  }
`;

const LayoutIronhackInternational = css`
  margin-bottom: 4.8rem;

  & > h2 {
    padding-bottom: 3.2rem;
  }

  p {
    margin-top: 1.6rem;
    color: ${config.palette.darkBlueTransparent('0.6')};
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    margin-bottom: 3.2rem;
  }
`;

const Article = styled.article`
  display: flex;
  justify-content: space-between;
  margin-top: 3.2rem;
  @media (max-width: ${config.resolutions.mobileL}) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

const BackgroundMobileTransparent = styled.div`
  display: none;
  @media (max-width: ${config.resolutions.mobileL}) {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    height: 7rem;
    width: 100vw;
    background-color: ${config.palette.white};
    opacity: 0.6;
    z-index: 8;
  }
`;

const ButtonApply = styled.a`
  background-color: ${config.palette.lightBlue};
  border-radius: 0.6rem;
  color: ${config.palette.white};
  font-size: 2rem;
  linbe-height: 2.3rem;
  padding: 2.8rem 0;
  text-align: center;
  text-decoration: none;
  width: 55%;
  @media (max-width: ${config.resolutions.mobileL}) {
    position: fixed;
    width: 90%;
    left: 2rem;
    bottom: 0.8rem;
    padding: 1.4rem 0;
    z-index: 9;
    font-size: 1.6rem;
    line-height: 125%;
  }
`;

const ButtonWhite = styled.a`
  background-color: ${config.palette.white};
  border-radius: 0.6rem;
  border: 0.1rem solid ${config.palette.darkBlueTransparent('0.1')};
  color: ${config.palette.lightBlue};
  cursor: pointer;
  font-size: 1.6rem;
  line-height: 150%;
  padding: 2.8rem 0;
  text-align: center;
  text-decoration: none;
  width: 35%;
  @media (max-width: ${config.resolutions.mobileL}) {
    width: 100%;
    padding: 1.4rem 0;
  }
`;

export {
  CampusInformation,
  Item,
  LayoutIronhackInternational,
  Article,
  BackgroundMobileTransparent,
  ButtonApply,
  ButtonWhite,
};
