import { updateDataLayer } from '../../lib/datalayer';

function emitViewDetailsEvent(): void {
  updateDataLayer({
    event: 'gaEvent',
    eventCategory: 'navigation',
    eventAction: 'click button',
    eventLabel: 'student projects::view more'
  });
}

export { emitViewDetailsEvent };
