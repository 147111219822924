import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const SQL = createIcon({
  displayName: 'SQL',
  path: (
    <>
      <path
        d="M25 21.4c-3 1.5-6.9 2.4-11 2.4s-8-.9-11-2.4c-1.1-.6-2-1.2-2.8-1.9a4 4 0 0 0-.2 1.3c0 4 6.3 7.2 14 7.2s14-3.2 14-7.2c0-.5 0-.9-.2-1.3-.8.7-1.7 1.3-2.7 1.9ZM28 7.2c0 4-6.3 7.3-14 7.3S0 11.2 0 7.2 6.3 0 14 0s14 3.2 14 7.2Z"
        fill="#14AADE"
      />
      <path
        d="M25 14.6C22 16 18.2 17 14 17S6 16 3 14.6C1.8 14 1 13.4.1 12.7A4 4 0 0 0 0 14c0 4 6.3 7.2 14 7.2S28 18 28 14c0-.4 0-.8-.2-1.3-.8.7-1.7 1.3-2.7 1.9Z"
        fill="#14AADE"
      />
    </>
  ),
  viewBox: '0 0 28 28',
});

export default SQL;
