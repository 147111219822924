import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const Tableau = createIcon({
  displayName: 'Tableau',
  path: (
    <>
      <path
        d="M14.4 20.3H16v-4.6h4.2v-1.6H16V9.6h-1.6V14h-4.2v1.6h4.2v4.6Z"
        fill="#F49102"
      />
      <path
        d="M6.7 26.8H8v-4h3.7v-1.3H8.1v-4H6.7v4H2.9v1.3h3.8v4Z"
        fill="#E57373"
      />
      <path
        d="M22.3 12.3h1.4v-4h3.8V7h-3.8V3h-1.4v4h-3.7v1.2h3.7v4Z"
        fill="#36B2B2"
      />
      <path
        d="M14.6 30h1.2v-2.9h2.6v-1h-2.6v-3h-1.2v3h-2.5v1h2.5V30Z"
        fill="#717291"
      />
      <path d="M6.7 12.3H8V8.2h3.8V7H8V3H6.7v4H3v1.2h3.8v4Z" fill="#FFD26F" />
      <path
        d="M26.6 18.3h1.2v-2.8h2.6v-1.1h-2.6v-2.9h-1.2v2.9h-2.5v1h2.5v2.9Z"
        fill="#717291"
      />
      <path
        clipRule="evenodd"
        d="M22.3 26.8h1.4v-4h3.8v-1.3h-3.8v-4h-1.4v4h-3.7v1.3h3.7v4Z"
        fill="#008BBB"
        fillRule="evenodd"
      />
      <path
        d="M18.2 3.6v-.8h-2.5V0h-1v2.8h-2.4v.8h2.5v2.8h.9V3.6h2.5ZM2.5 18.1h1v-2.8h2.4v-.8H3.4v-2.8h-.9v2.8H0v.9h2.5V18Z"
        fill="#ABDDDD"
      />
    </>
  ),
  viewBox: '0 0 31 30',
});

export default Tableau;
