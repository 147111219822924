import styled from 'styled-components';
import config from '../config';
import Layout from '../atoms/layout';

const Wrapper = styled(Layout)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  h2 {
    width: 100%;
  }

  .change-format {
    color: ${config.palette.lightBlue};
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 140%;
    margin-top: 3.2rem;
    text-align: center;
  }
`;

const CourseFormat = styled.article`
  border: 0.2rem solid transparent;
  border-radius: 0.4rem;
  box-shadow: 0 0.4rem 0.8rem ${config.palette.boxShadow};
  cursor: pointer;
  margin-bottom: 6.4rem;
  margin-top: 2rem;
  padding: 3.2rem 2.4rem 2.4rem;
  transition: 0.3s ease-in-out box-shadow, 0.3s ease-in-out border-color;
  width: calc(25% - 1.6rem);

  b {
    display: block;
    height: 6rem;
  }

  &:hover {
    box-shadow: 0 0.4rem 0.8rem ${config.palette.boxShadowHover};
  }

  &.selected {
    border-color: ${config.palette.lightBlue};
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    margin-bottom: 2.4rem;
    width: 100%;

    b {
      display: inline;
      height: auto;
    }
  }
`;

export { CourseFormat, Wrapper };
