import React from 'react';
import { Box, forwardRef, SimpleGrid, Text } from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';

const CareerServices = forwardRef<null, 'div'>(
  (props, ref): React.ReactElement => {
    const { t } = useTranslation();

    return (
      <Box
        bg={['orange.10', null, 'transparent']}
        margin={[null, null, null, 'auto']}
        maxW={[null, null, null, '1296px']}
        p={[3, null, 4]}
        px={[null, null, null, 0]}
        ref={ref}
      >
        <Text color="orange.100" textStyle="eyebrow">
          {t('eyebrow:eyebrow.careerServices')}
        </Text>
        <Text as="h2" textStyle="title1">
          {t('career-services:careerServices.mainHeadline.title')}
        </Text>
        <SimpleGrid
          bg="orange.10"
          borderRadius="card"
          columns={[1, null, 2]}
          mt={[2, null, 4]}
          p={[0, null, 3]}
          spacing={4}
        >
          <Box order={[2, null, 1]}>
            <Text as="h3" mb={2} textStyle="title2">
              {t('career-services:careerServices.methodology.title')}
            </Text>
            <Text color="darkBlue.64" textStyle="body3">
              {t('career-services:careerServices.mainHeadline.subtitle')}
            </Text>
          </Box>
          <Box
            align="end"
            borderRadius="card"
            height="100%"
            mt={[3, null, 0]}
            order={[1, null, 2]}
            overflow="hidden"
          >
            <StaticImage
              alt="Ironhack Career Week"
              layout="constrained"
              placeholder="blurred"
              src="../../../static/assets/career/week.png"
            />
          </Box>
        </SimpleGrid>
      </Box>
    );
  }
);

export default CareerServices;
