import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const Compliance = createIcon({
  displayName: 'ComplianceIcon',
  viewBox: '0 0 25 30',
  path: (
    <>
      <path
        d="M0 3.64 12.314 0 24.63 3.64v5.592c0 9.795-4.55 14.794-12.315 20.768C4.55 24.03 0 19.027 0 9.232V3.641Z"
        fill="#DDDBFF"
      />
      <circle cx="12.5" cy="12.5" fill="#A65B82" r="7.5" />
      <path
        d="M11.205 14.11 8.461 11.27 7 12.716 11.154 17 18 10.497 16.616 9l-5.41 5.11Z"
        fill="#fff"
      />
    </>
  ),
});

export default Compliance;
