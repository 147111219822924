import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const CSS = createIcon({
  displayName: 'CSSIcon',
  viewBox: '0 0 27 30',
  path: (
    <>
      <path
        d="M2.2 26.86 0 .06 26.2.1l-2.32 26.76-10.68 3.09-11-3.1Z"
        fill="#1B73BA"
      />
      <path d="M13.2 27.36V2.6l10.78.04-1.92 22.15-8.86 2.57Z" fill="#1C88C7" />
      <path
        d="M21.3 5.77H4.72L5.16 9h7.88l-7.75 3.33.45 3.13h11.41l-.41 4.37-3.87.75-3.5-.9-.27-2.48H5.88l.43 5.16 6.9 1.93 6.62-2.1.85-10.1h-7l7.63-3.16V5.77Z"
        fill="#fff"
      />
    </>
  ),
});

export default CSS;
