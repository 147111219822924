import styled, { css } from 'styled-components';

import config from '../config';

const Process = styled.section`
  display: -webkit-box;
  overflow-x: scroll;
  margin: 0 auto 4.8rem;
  max-width: 1296px;

  & > article:first-child {
    margin-left: calc((100% - 129.6rem) / 2);
  }

  & > article:last-child {
    position: relative;

    &:after {
      position: absolute;
      content: '';
      width: 0.1rem;
      height: 0.1rem;
      right: calc((100% - 129.6rem) / 2);
    }
  }

  @media (max-width: ${config.resolutions.desktopS}) {
    & > article:first-child {
      margin-left: calc((100% - 112rem) / 2);
    }

    & > article:last-child:after {
      right: calc((100% - 112rem) / 3.2);
    }
  }

  @media (max-width: ${config.resolutions.tablet}) {
    & > article:first-child {
      margin-left: calc((100% - 70.4rem) / 2);
    }
    & > article:last-child:after {
      right: calc((100% - 70.4rem) / 2);
    }
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    margin-bottom: 3.2rem;

    & > article:first-child {
      margin-left: 0;
    }
    & > article:last-child:after {
      right: 0;
    }
  }
`;

const CardProcess = styled('article')<{ background: string }>`
  padding: 2.4rem 3.2rem;
  margin-right: 3.2rem;
  width: 40%;
  background-color: ${(props) => props.background};
  border-radius: 0.6rem;
  font-size: 2rem;
  line-height: 160%;
  color: ${config.palette.darkBlueTransparent('0.6')};

  b {
    display: block;
    margin: 0.8rem 0;
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    padding: 2.4rem 3.2rem 2.4rem 0;
    margin: 0;
    width: 80%;
    background-color: #77c0bc;
    font-size: 1.6rem;
    line-height: 150%;
    border-radius: 0;

    &:first-child {
      padding-left: 3.2rem;
    }
  }
`;

const LayoutApplicationProcess = css`
  margin: 3.2rem 0;

  @media (max-width: ${config.resolutions.mobileL}) {
    margin-bottom: 0;
    padding-top: 2.4rem;
    background-color: #77c0bc;
  }
`;

export { Process, CardProcess, LayoutApplicationProcess };
