import React from 'react';
import { withTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import TZ from '../../lib/timezones';
import classNames from 'classnames';
import { Title1 } from '../atoms/headers';
import { Text1, Text2 } from '../atoms/body';
import { CourseFormat, Wrapper } from './styles';

interface Props {
  t: TFunction;
  currentValue: string;
  handleChange: (value: string) => void;
}

const OurCoursesTimezone: React.SFC<any> = (props: Props) => {
  const { t, currentValue, handleChange } = props;
  const europeClass = classNames({ selected: currentValue === TZ.EUROPE });
  const usaClass = classNames({ selected: currentValue === TZ.USA });
  const latamClass = classNames({ selected: currentValue === TZ.LATAM });
  const brazilClass = classNames({ selected: currentValue === TZ.BRAZIL });

  const toggleFormat = (format: string): void => {
    handleChange(format);
    const courseFormat = document.getElementById('courses-format');
    if (courseFormat) {
      window.scroll({
        left: 0,
        top: courseFormat.offsetTop,
        behavior: 'smooth'
      });
    }
  };

  return (
    <Wrapper>
      <Title1 as="h2">
        {t('course-web-dev-rmt:campus-rmt.selectTimezone.title')}
      </Title1>
      <CourseFormat
        className={europeClass}
        onClick={(): void => toggleFormat(TZ.EUROPE)}
      >
        <Text1>
          {t('course-web-dev-rmt:campus-rmt.selectTimezone.timezones.europe')}
        </Text1>
        <Text2 className="change-format" as="div">
          {currentValue === TZ.EUROPE
            ? t('course-web-dev-rmt:campus-rmt.selectTimezone.btnSelected')
            : t('course-web-dev-rmt:campus-rmt.selectTimezone.btnDefault')}
        </Text2>
      </CourseFormat>
      <CourseFormat
        className={usaClass}
        onClick={(): void => toggleFormat(TZ.USA)}
      >
        <Text1>
          {t('course-web-dev-rmt:campus-rmt.selectTimezone.timezones.usa')}
        </Text1>
        <Text2 className="change-format" as="div">
          {currentValue === TZ.USA
            ? t('course-web-dev-rmt:campus-rmt.selectTimezone.btnSelected')
            : t('course-web-dev-rmt:campus-rmt.selectTimezone.btnDefault')}
        </Text2>
      </CourseFormat>
      <CourseFormat
        className={latamClass}
        onClick={(): void => toggleFormat(TZ.LATAM)}
      >
        <Text1>
          {t('course-web-dev-rmt:campus-rmt.selectTimezone.timezones.latam')}
        </Text1>
        <Text2 className="change-format" as="div">
          {currentValue === TZ.LATAM
            ? t('course-web-dev-rmt:campus-rmt.selectTimezone.btnSelected')
            : t('course-web-dev-rmt:campus-rmt.selectTimezone.btnDefault')}
        </Text2>
      </CourseFormat>
      <CourseFormat
        className={brazilClass}
        onClick={(): void => toggleFormat(TZ.BRAZIL)}
      >
        <Text1>
          {t('course-web-dev-rmt:campus-rmt.selectTimezone.timezones.brazil')}
        </Text1>
        <Text2 className="change-format" as="div">
          {currentValue === TZ.BRAZIL
            ? t('course-web-dev-rmt:campus-rmt.selectTimezone.btnSelected')
            : t('course-web-dev-rmt:campus-rmt.selectTimezone.btnDefault')}
        </Text2>
      </CourseFormat>
    </Wrapper>
  );
};

export default withTranslation()(OurCoursesTimezone);
