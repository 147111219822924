import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const Statistics = createIcon({
  displayName: 'Statistics',
  path: (
    <>
      <path
        d="M27.1 26.25a3.73 3.73 0 0 1-4.17 3.72 3.82 3.82 0 0 1-3.26-3.88V3.9A3.83 3.83 0 0 1 22.94.03a3.73 3.73 0 0 1 4.16 3.72v22.5Z"
        fill="#FFD26F"
      />
      <path
        d="M3.72 22.57a3.72 3.72 0 1 1 0 7.43 3.72 3.72 0 0 1 0-7.43Zm9.77-11.26a3.82 3.82 0 0 0-3.61 3.9v9.98c0 2.71 1.2 4.36 2.94 4.7a3.72 3.72 0 0 0 4.46-3.65v-11.2a3.73 3.73 0 0 0-3.72-3.73h-.07Z"
        fill="#F49102"
      />
    </>
  ),
  viewBox: '0 0 28 30',
});

export default Statistics;
