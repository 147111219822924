import React, { useEffect, useState, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, BoxProps, Text, Grid } from '@chakra-ui/react';
import _ from 'lodash/fp';

import { usePageContext } from '../../../../context/PageContext';
import { TDatoCmsFinancingOption } from '../../../../types/datocms';
import FinancingOptionsCard from './FinancingOptionCard';
import FinancingOptionCountrySelector from './FinancingOptionCountrySelector';

type CampusCourseFinancingOptionsProps = BoxProps & {
  financingOptions: TDatoCmsFinancingOption[];
};

const CampusCourseFinancingOptions = (props, ref): React.ReactElement => {
  const { financingOptions, ...boxProps } = props;
  const { t } = useTranslation(['course-campus']);
  const { campusCode } = usePageContext();
  const isRemote = campusCode === 'rmt';
  const [selectedCountry, setSelectedCountry] = useState<string>();
  const [visibleFinancingOptions, setVisibleFinancingOptions] = useState<
    TDatoCmsFinancingOption[]
  >(() => (isRemote ? [] : financingOptions));

  useEffect(() => {
    setVisibleFinancingOptions(() => {
      if (isRemote) {
        if (selectedCountry) {
          return _.filter<TDatoCmsFinancingOption[]>(
            _.pipe(
              _.prop('countries'),
              _.map('isoCode'),
              _.includes(selectedCountry)
            )
          )(financingOptions);
        }
        return [];
      }
      return financingOptions;
    });
  }, [selectedCountry, isRemote, financingOptions]);

  return (
    <Box
      margin={[null, null, null, 'auto']}
      maxW={[null, null, null, '1296px']}
      p={[3, null, 4]}
      px={[null, null, null, 0]}
      ref={ref}
      {...boxProps}
    >
      <Text as="h3" textStyle="title1">
        {t('courseCampus.financingOptions.title')}
      </Text>
      <Text color="darkBlue.64" mt={1} textStyle="body3">
        {t('courseCampus.financingOptions.moreInfo')}
      </Text>
      {isRemote && (
        <FinancingOptionCountrySelector
          countries={_.uniqBy(
            'isoCode',
            _.flatMap('countries', financingOptions)
          )}
          mt={6}
          onCountrySelected={setSelectedCountry}
        />
      )}
      {visibleFinancingOptions.length !== 0 && (
        <Grid
          gap={4}
          mt={[3, null, null, 5]}
          overflowX="scroll"
          templateColumns={[
            `repeat(${visibleFinancingOptions.length}, 1fr)`,
            null,
            'repeat(2, 1fr)',
          ]}
        >
          {visibleFinancingOptions.map((financingOption) => (
            <FinancingOptionsCard
              country={selectedCountry}
              financingOption={financingOption}
              key={financingOption.salesforceId}
            />
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default forwardRef<HTMLDivElement, CampusCourseFinancingOptionsProps>(
  CampusCourseFinancingOptions
);
