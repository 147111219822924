import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const NetworkTraffic = createIcon({
  displayName: 'NetworkTrafficIcon',
  viewBox: '0 0 28 28',
  path: (
    <path
      d="M27.53 10.4A14 14 0 1 0 .47 17.58a14 14 0 0 0 27.06-7.15v-.01ZM15.77 25.53l-.4.05c-.9.11-1.83.11-2.75 0l-.38-.05-.57-.12a40.85 40.85 0 0 1-.9-5.66c1.08 0 2.16.1 3.24.1 1.09 0 2.16 0 3.22-.1-.17 1.9-.47 3.8-.9 5.66l-.56.12ZM2.6 16.33c0-.18-.08-.37-.1-.56-.03-.18 0-.28-.07-.42a10.5 10.5 0 0 1 0-2.73c0-.14 0-.28.05-.42.06-.14.07-.37.1-.56 1.87-.42 3.76-.72 5.67-.88-.08 1.08-.08 2.17-.08 3.24 0 1.07 0 2.16.09 3.22-1.9-.16-3.8-.46-5.66-.89ZM12.21 2.5l.42-.08a11.55 11.55 0 0 1 2.7 0l.42.08.55.1c.42 1.86.72 3.75.88 5.66-2.15-.11-4.3-.11-6.45 0 .18-1.9.5-3.79.94-5.65l.54-.11ZM17.5 14c0 1.17 0 2.28-.1 3.4-2.27.12-4.53.12-6.8 0-.1-1.12-.1-2.23-.1-3.4s0-2.27.1-3.4c2.27-.12 4.53-.12 6.8 0 .1 1.13.1 2.23.1 3.4Zm2.24-3.22c1.9.16 3.8.46 5.66.89 0 .18.08.37.1.56.03.18 0 .28.07.42.12.9.12 1.82 0 2.73 0 .14 0 .28-.06.42-.05.14-.07.37-.1.56-1.86.42-3.76.72-5.66.88 0-1.07.1-2.14.1-3.23 0-1.08-.02-2.17-.11-3.23Zm4.82-1.68a47.52 47.52 0 0 0-4.99-.68 47.65 47.65 0 0 0-.67-4.98c2.5 1.16 4.5 3.17 5.66 5.66ZM9.1 3.44a47.52 47.52 0 0 0-.68 4.98c-1.67.14-3.33.37-4.98.68C4.6 6.6 6.6 4.6 9.1 3.44ZM3.44 18.9c1.65.31 3.31.54 4.98.68.14 1.67.37 3.33.68 4.98a11.67 11.67 0 0 1-5.66-5.66Zm15.46 5.66c.31-1.65.54-3.31.67-4.98 1.68-.14 3.34-.37 4.99-.68a11.67 11.67 0 0 1-5.66 5.66Z"
      fill="#FFD26F"
    />
  ),
});

export default NetworkTraffic;
