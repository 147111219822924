import React, { useEffect, useRef, useState } from 'react';
import uniqid from 'uniqid';
import { Trans, useTranslation } from 'react-i18next';
import {
  Box,
  BoxProps,
  Button,
  Center,
  Container,
  Flex,
  Grid as CGrid,
  HStack,
  Icon,
  Link,
  SimpleGrid,
  Spacer,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from '@chakra-ui/react';
import { FaRegClock } from 'react-icons/fa';
import { IoEllipsisHorizontalSharp } from 'react-icons/io5';
import { GatsbyImage } from 'gatsby-plugin-image';
import _ from 'lodash/fp';

import * as CourseSkillIcons from '../../../components/CourseSkillIcon';
import Navbar from '../../../components/NavBar';
import Grid from '../../../components/Grid';
import ApplicationProcess from '../../../components/ApplicationProcess';
import CareerServices from '../../../components/CareerServices';
import Instructors from '../../../components/Instructors';
import StudentProjects from '../../../components/StudentProjects';
import MoreQuestions from '../../../components/MoreQuestions';
import Footer from '../../../components/Footer';
import { Text2 } from '../../../components/atoms/body';
import NewDayAtIronhack from '../../../components/DayAtIronhack/experiments';
import DayAtIronhackRemote from '../../../components/DayAtIronhackRemote';
import NewNavbar, {
  MenuProps,
} from '../../../components/CampusCourseNavbar/experiments';
import OurCoursesTimezone from '../../../components/OurCoursesTimezone';
import NewCourseInformation from '../../../components/InformationAboutTheCourse/experiments';
import CompanyShowcase from '../../../containers/CompanyShowcase';
import CampusCourseFinancingOptions from '../../../components/FinancialOptions/experiments/CampusCourseFinancingOptions';
import { Title1 } from '../../../components/atoms/headers';
import CtaCampusCourse from '../../../components/CtaCampusCourse';
import { StructuredContent } from '../../../components/StructuredContent';
import {
  CampusInformation,
  Item,
  LayoutIronhackInternational,
} from '../styles';
import { CampusCoursePageProps } from '../../../interface/template';
import { courseLookup, formatLookup } from '../../../lib/utils';
import {
  SaveCampusVisited,
  SaveCourseVisited,
} from '../../../storage/provider';
import DatoContactInfo from '../../../services/datoCampusContactInformation';
import { processDatoCmsAssests } from '../../../services/datoUtilities';
import useScroll from '../../../hooks/useScroll';
import { getLinkByPageName } from '../../../lib/links';
import { isVirtualCampus } from '../../../lib/campus';
import * as gaEvents from '../../../components/CtaCampusCourse/ga_events';
import * as upcomingCohortEvents from '../../../components/UpcomingCohorts/ga_events';
import useUserLocation from '../../../hooks/useUserLocation';
import CareerServicesIcon from '../../../components/Illustrations/CareerServicesIcon';
import PaymentOptionsIcon from '../../../components/Illustrations/PaymentOptionsIcon';
import CohortCard from '../../../containers/CohortCard';
import Hexagon from '../../../components/Shapes/Hexagon';
import Banner from '../../../components/Banner';
import NewCampusValueProposition from '../../../containers/NewCampusValueProposition';
import ShortCourseEntryPoint from '../../../containers/ShortCourseEntryPoint';
import Apprenticeship from '../../../containers/Apprenticeship';
import FrequentAskedQuestions from '../../../components/FAQ';

type CourseCode = 'cysec' | 'data' | 'uxui' | 'web';

const courseSkills = {
  cysec: [
    { CourseSkill: CourseSkillIcons.NetworkTraffic, skill: 'networkTraffic' },
    {
      CourseSkill: CourseSkillIcons.PreventionSystems,
      skill: 'preventionSystems',
    },
    { CourseSkill: CourseSkillIcons.Compliance, skill: 'compliance' },
  ],
  data: [
    { CourseSkill: CourseSkillIcons.Python, skill: 'python' },
    { CourseSkill: CourseSkillIcons.SQL, skill: 'sql' },
    { CourseSkill: CourseSkillIcons.Tableau, skill: 'tableau' },
    { CourseSkill: CourseSkillIcons.Statistics, skill: 'statistics' },
  ],
  uxui: [
    { CourseSkill: CourseSkillIcons.UserExperience, skill: 'userExperience' },
    { CourseSkill: CourseSkillIcons.UserResearch, skill: 'userResearch' },
    { CourseSkill: CourseSkillIcons.Figma, skill: 'figma' },
    { CourseSkill: CourseSkillIcons.DesignThinking, skill: 'designThinking' },
  ],
  web: [
    { CourseSkill: CourseSkillIcons.HTML, skill: 'html' },
    { CourseSkill: CourseSkillIcons.CSS, skill: 'css' },
    { CourseSkill: CourseSkillIcons.Js, skill: 'js' },
    { CourseSkill: CourseSkillIcons.ReactIcon, skill: 'react' },
  ],
};

type HeroFeatureContentProps = {
  campus: string;
  course: CourseCode;
  ns: string;
  showFrenchRegistration?: boolean;
};

const HeroFeatureContent = (
  props: HeroFeatureContentProps & BoxProps
): React.ReactElement => {
  const { campus, course, showFrenchRegistration, ns, ...boxProps } = props;
  const { t } = useTranslation();

  const courseCampusKey =
    course === 'cysec'
      ? 'course-cybersecurity-campus:cysec'
      : 'course-campus:courseCampus';

  return (
    <Box {...boxProps}>
      <Text maxW="86ch" textStyle="title1">
        <Trans
          components={{
            mark: <Text as="span" color="electricBlue.100" />,
          }}
          i18nKey={`${ns}:${course}.mainHeadline.headline`}
          values={{ campus }}
        />
      </Text>
      <Text mt={1.5} textStyle="body4">
        {t(`${ns}:${course}.variant.mainHeadline.description`)}
      </Text>
      {showFrenchRegistration && (
        <Text textStyle="body4">
          <Trans
            components={[
              <Link
                href={t(
                  `course-campus-par:courseCampus-par.header.formation.${course}.link`
                )}
                isExternal
              >
                rendez-vous ici
              </Link>,
            ]}
            defaults={t(
              `course-campus-par:courseCampus-par.header.formation.${course}.text`
            )}
            i18nKey={t(
              `course-campus-par:courseCampus-par.header.formation.${course}.text`
            )}
          />
        </Text>
      )}
      <HStack mt={2} spacing={1.5}>
        <Icon as={FaRegClock} boxSize="20px" />
        <Text textStyle="body4">
          {t(`course-campus:courseCampus.experiment.bootcampDuration`, {
            ftDuration: t(`${courseCampusKey}.formats.fullTime.weeks`),
            ptDuration: t(`${courseCampusKey}.formats.partTime.weeks`),
          })}
        </Text>
      </HStack>
      <CGrid
        gap={[2, null, 4]}
        mt={[2, null, 3]}
        templateColumns="repeat(5, minmax(4.8rem, auto))"
      >
        {courseSkills[course].map(({ CourseSkill, skill }) => (
          <VStack key={`${course}-${skill}`}>
            <Center bg="darkBlue.100" borderRadius="sm" boxSize="4.8em">
              <CourseSkill boxSize="30px" />
            </Center>
            <Text maxW="fit-content" textAlign="center">
              {t(`${ns}:${course}.variant.skills.${skill}`)}
            </Text>
          </VStack>
        ))}
        <VStack>
          <Center
            borderColor="darkBlue.100"
            borderRadius="sm"
            borderWidth={1}
            boxSize="4.8em"
          >
            <Icon as={IoEllipsisHorizontalSharp} boxSize={10} />
          </Center>
          <Text>
            {t('course-campus:courseCampus.experiment.courseSkills.andMore')}
          </Text>
        </VStack>
      </CGrid>
    </Box>
  );
};

const CampusCourse = (props: CampusCoursePageProps): React.ReactElement => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { data } = props;
  const { locale, campusCode, courseCode, cohorts, pageName } =
    props.pageContext;
  const { userLocation, isLoading: isUserLocationLoading } = useUserLocation();
  const [selectedTimezone, setSelectedTimezone] = useState(
    userLocation.remoteTimezone
  );

  const navbarRef = useRef<HTMLDivElement>(null);
  const scrollConfig = { offset: navbarRef?.current?.offsetHeight };

  const [, headerRef, headerIsVisible] = useScroll(scrollConfig, '0%');
  const [scrollToCareerServices, careerServicesRef, careerServicesIsVisible] =
    useScroll(scrollConfig);
  const [scrollToCourseInfo, courseInfoRef, courseInfoIsVisible] =
    useScroll(scrollConfig);
  const [scrollToCoursesFormat, coursesFormatRef, coursesFormatIsVisible] =
    useScroll(scrollConfig);
  const [scrollToPaymentOptions, paymentOptionsRef, paymentOptionsIsVisible] =
    useScroll(scrollConfig);
  const [scrollToTeachers, teachersRef, teachersIsVisible] =
    useScroll(scrollConfig);
  const [scrollToTypicalDay, typicalDayRef, typicalDayIsVisible] =
    useScroll(scrollConfig);
  const [scrollToVae, vaeRef, vaeIsVisible] = useScroll(scrollConfig);

  SaveCampusVisited(campusCode);
  SaveCourseVisited(`${courseCode}ft`);

  const fileI18n = `course-${courseLookup(courseCode)}-campus`;
  const courseName = t(`course-list:courseList.${courseCode}.title`);
  const campuses = new DatoContactInfo(locale).contactInformationByLanguage();
  const currentCampus = campuses.find(
    (info) => info.campus.code.toLowerCase() === campusCode
  );
  const campusName = (currentCampus && currentCampus.campus.description) || '';
  const hideCtas = courseCode.startsWith('data') && campusCode === 'mia';
  const campusAndCourseCohorts = cohorts.filter((cohort: Cohort): boolean => {
    const keepCohort =
      cohort.campus_code === campusCode &&
      cohort.course_code.startsWith(courseCode);
    if (keepCohort && cohort.campus_code === 'rmt') {
      return cohort.timezone === selectedTimezone;
    }
    return keepCohort;
  });

  useEffect(() => {
    setSelectedTimezone(userLocation.remoteTimezone);
  }, [userLocation.remoteTimezone]);

  const assets = processDatoCmsAssests(props.data.allDatoCmsAssetsByPage);

  const datoCampusInfo = props.data.allDatoCmsCampusinformation.nodes[0];

  const isInFranceCampusInFrench =
    language === 'fr' && ['par', 'bod'].includes(campusCode);
  const isFrenchWeb = isInFranceCampusInFrench && courseCode === 'web';
  const isFrenchData = isInFranceCampusInFrench && courseCode === 'data';
  const isFrenchVae = isFrenchWeb || isFrenchData;
  const isLondon = campusCode === 'lon';

  const hideMenuItemIfNoContent = (menu: MenuProps) =>
    isFrenchVae || menu.key !== t('course-campus:courseCampus.navbar.vae');

  const navbarMenu = _.filter(hideMenuItemIfNoContent)([
    {
      isVisible: coursesFormatIsVisible || headerIsVisible,
      key: t('course-campus:courseCampus.navbar.ourCourses'),
      ref: coursesFormatRef,
      scroll: scrollToCoursesFormat,
    },
    {
      isVisible: courseInfoIsVisible,
      key: t('course-campus:courseCampus.navbar.courseInfo'),
      ref: courseInfoRef,
      scroll: scrollToCourseInfo,
    },
    {
      isVisible: careerServicesIsVisible,
      key: t('course-campus:courseCampus.navbar.careerServices'),
      ref: careerServicesRef,
      scroll: scrollToCareerServices,
    },
    {
      isVisible: paymentOptionsIsVisible,
      key: t('course-campus:courseCampus.navbar.paymentOptions'),
      ref: paymentOptionsRef,
      scroll: scrollToPaymentOptions,
    },
    {
      isVisible: teachersIsVisible,
      key: t('course-campus:courseCampus.navbar.teachers'),
      ref: teachersRef,
      scroll: scrollToTeachers,
    },
    {
      isVisible: typicalDayIsVisible,
      key: t('course-campus:courseCampus.navbar.typicalDay'),
      ref: typicalDayRef,
      scroll: scrollToTypicalDay,
    },
    {
      isVisible: vaeIsVisible,
      key: t('course-campus:courseCampus.navbar.vae'),
      ref: vaeRef,
      scroll: scrollToVae,
    },
  ]);

  const whyShouldYouStudyKey =
    campusCode !== 'rmt'
      ? `course-${courseLookup(
          courseCode
        )}-${campusCode}:${courseCode}-${campusCode}.whyShouldYouStudy`
      : `course-web-dev-rmt:${courseCode}-rmt.whyShouldYouStudy`;

  const cohortFeatures = {
    careerServices: {
      Icon: CareerServicesIcon,
      scroll: scrollToCareerServices,
    },
    paymentOptions: {
      Icon: PaymentOptionsIcon,
      scroll: scrollToPaymentOptions,
    },
  };

  const showTimezoneSelector =
    campusCode === 'rmt' &&
    !isUserLocationLoading &&
    _.isEmpty(selectedTimezone);
  const showCoursesFormat =
    (campusCode === 'rmt' &&
      !isUserLocationLoading &&
      !_.isEmpty(selectedTimezone)) ||
    campusCode !== 'rmt';
  const formatGroupedCohorts = _.groupBy(
    (c) => formatLookup(c.course_code.slice(-2)),
    campusAndCourseCohorts
  );
  const cohortFormats = _.intersection(Object.keys(formatGroupedCohorts), [
    'fullTime',
    'partTime',
  ]);

  const courseCampusKey =
    courseCode === 'cysec'
      ? 'course-cybersecurity-campus:cysec'
      : 'course-campus:courseCampus';

  const vaeData = data.allDatoCmsVaeCertificationNew.nodes.find(
    (el) => el.course.webCode === courseCode
  );

  return (
    <Box maxW="144rem" mx="auto">
      {isLondon && <Banner type="londonWhitepaper" />}
      <Flex
        flexFlow="column"
        maxH={['68rem', 'none']}
        minH="56rem"
        overflow="hidden"
        pos="relative"
        ref={headerRef}
      >
        {/* Dont want to touch Navbar so wrapped in Box 🤷🏻‍♀️ */}
        <Box as={Navbar} isDarkBackground pageName={pageName} zIndex="docked" />
        <Box
          as="video"
          autoPlay
          height="100%"
          loop
          maxW="none"
          muted
          playsInline
          pos="absolute"
          poster="https://image.mux.com/FEVgnjFZBecwAVkQ4At1ku6c0200s3b4tj/thumbnail.jpg?time=0&height=560"
          zIndex="hide"
        >
          <source
            src="https://stream.mux.com/FEVgnjFZBecwAVkQ4At1ku6c0200s3b4tj/medium.mp4"
            type="video/mp4"
          />
        </Box>
        <Hexagon
          boxSize="88em"
          color="white"
          left={['-28em', null, '-10em']}
          opacity="90%"
          pos="absolute"
          top={['10em', null, '2em']}
          transform={['rotate(14deg)', null, 'rotate(22deg)']}
          zIndex="base"
        />
        <Flex
          direction={['column-reverse', null, 'row']}
          flexGrow={1}
          mx={[3, null, 10]}
          zIndex={1}
        >
          <Container
            alignSelf="center"
            h="fit-content"
            maxW="min-content"
            pb={[2, null, 0]}
          >
            <HeroFeatureContent
              campus={campusName}
              course={courseCode as CourseCode}
              maxW="96ch"
              ns={fileI18n}
              showFrenchRegistration={isFrenchVae}
            />
            {!hideCtas && (
              <CtaCampusCourse
                primaryCtaProps={{
                  href: getLinkByPageName('allCourses/application'),
                  onClick: gaEvents.emitApplyEvent,
                }}
                primaryCtaText={t('menu:menu.cta')}
                secondaryCtaProps={{
                  onClick: scrollToCourseInfo,
                }}
                secondaryCtaText={t(
                  'course-campus:courseCampus.header.ctaButton'
                )}
              />
            )}
          </Container>
          <Spacer />
        </Flex>
      </Flex>
      <NewNavbar
        bg={headerIsVisible ? '#F4F5F699' : 'white'}
        ctaProps={{
          onClick: gaEvents.emitApplyEvent,
          href: getLinkByPageName('allCourses/application'),
        }}
        ctaText={t('menu:menu.cta')}
        headerIsVisible={headerIsVisible}
        menu={navbarMenu}
        ref={navbarRef}
        titleForMobile={t('course-campus:courseCampus.navbar.header', {
          course: courseName,
          campus: campusName,
        })}
      />
      {!_.isEmpty(datoCampusInfo?.campusvalueprops) && (
        <NewCampusValueProposition campusInfo={datoCampusInfo} />
      )}
      <Box bg="#F4F5F699" pl={[0, null, 10]} py={4} ref={coursesFormatRef}>
        <Text mb={4} ml={[3, null, 0]} textStyle="title1">
          {t('course-campus:courseCampus.experiment.ourCourses.title')}
        </Text>
        <Text mb={4} ml={[3, null, 0]} mr={[3, null, 10]} textStyle="body4">
          {isInFranceCampusInFrench &&
            t('course-campus-par:courseCampus-par.courseFormat.description')}
        </Text>
        {showTimezoneSelector && (
          <Box
            mr={[null, null, 10]}
            p={[3, null, 4]}
            px={[null, null, null, 0]}
          >
            <OurCoursesTimezone
              currentValue={selectedTimezone}
              handleChange={setSelectedTimezone}
            />
          </Box>
        )}
        {showCoursesFormat && (
          <Tabs
            bg="white"
            onChange={(i): void =>
              upcomingCohortEvents.emitFormatEvent(cohortFormats[i])
            }
          >
            <TabList>
              {cohortFormats.map((format) => (
                <Tab key={`${format}-tab`}>
                  <Text
                    mr={[0.5, null, 1]}
                    textStyle={['body2', null, 'body1']}
                  >
                    {t(
                      `course-campus:courseCampus.experiment.formats.${format}.title`
                    )}
                  </Text>
                  <Text
                    color="darkBlue.40"
                    textStyle={['body6', null, 'body4']}
                  >
                    {'('}
                    {t(`${courseCampusKey}.formats.${format}.weeks`)}
                    {')'}
                  </Text>
                </Tab>
              ))}
            </TabList>
            <TabPanels>
              {cohortFormats.map((format) => (
                <TabPanel
                  as={HStack}
                  key={`${format}-panel`}
                  overflowX="scroll"
                  pl={5}
                  py={5}
                  spacing={3}
                >
                  {formatGroupedCohorts[format].map((cohort, index) => {
                    const tag =
                      index === 0
                        ? data.datoCmsCampusCourse?.mainFomoTag
                        : index === 1
                        ? data.datoCmsCampusCourse?.secondaryFomoTag
                        : null;
                    return (
                      <CohortCard
                        applyCta={{
                          onClick: upcomingCohortEvents.emitApplyEvent,
                        }}
                        cohort={cohort}
                        financingOptions={{ onClick: scrollToPaymentOptions }}
                        key={cohort.id}
                        {...(tag
                          ? {
                              tagText: tag,
                              tagVariant:
                                index === 0 ? 'mainFomo' : 'secondaryFomo',
                            }
                          : {})}
                      />
                    );
                  })}
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        )}
        <Text ml={[3, null, 0]} my={4} textStyle="body1">
          {t('course-campus:courseCampus.formats.experiment.info.title')}
        </Text>
        <SimpleGrid columns={2} mr={[null, null, 10]} spacing={[2, null, 12]}>
          {Object.entries(cohortFeatures).map(([key, feature]) => (
            <Stack
              align={['center', null, 'initial']}
              direction={['column', null, 'row']}
              key={`feature-${key}`}
              spacing={3}
            >
              <feature.Icon boxSize="10.4rem" />
              <Stack
                maxW={['18ch', null, 'none']}
                textAling={['center', null, 'initial']}
                textStyle="body4"
              >
                <Text fontWeight="bold">
                  {t(
                    `course-campus:courseCampus.experiment.ourCourses.details.${key}.title`
                  )}
                </Text>
                <Text color="darkBlue.64">
                  {t(
                    `course-campus:courseCampus.experiment.ourCourses.details.${key}.description`
                  )}
                </Text>
                <Button
                  justifyContent={['center', null, 'normal']}
                  onClick={feature.scroll}
                  variant="link"
                  w="max-content"
                >
                  {t('course-campus:courseCampus.formats.experiment.info.cta')}
                </Button>
              </Stack>
            </Stack>
          ))}
        </SimpleGrid>
        {isFrenchWeb && (
          <Apprenticeship mr={[null, null, 10]} mt={[10, null, 12]} />
        )}
      </Box>
      <NewCourseInformation
        assets={assets}
        campusCode={campusCode}
        content={t(
          `${fileI18n}:${courseCode}.${formatLookup(
            'ft'
          )}.whatWillYouLearn.contents`,
          {
            returnObjects: true,
            campus: campusName,
          }
        )}
        course={courseCode}
        courseName={courseName}
        ref={courseInfoRef}
        titleSyllabus={t(
          `course-campus:courseCampus.downloadSyllabus.fullTime`,
          {
            course: courseName,
            campus: campusName,
          }
        )}
      />
      <ShortCourseEntryPoint campusCode={campusCode} courseCode={courseCode} />
      <CareerServices ref={careerServicesRef} />
      <CompanyShowcase
        companies={props.data.datoCmsCompanyShowcase.btob}
        title={t('home:home.companies.title')}
      />
      <CampusCourseFinancingOptions
        financingOptions={data.allDatoCmsFinancingOption.nodes}
        ref={paymentOptionsRef}
      />
      <ApplicationProcess campus={campusName} course={courseName} />
      <Instructors
        campus={campusName}
        campusCode={campusCode}
        course={courseName}
        courseCode={courseCode}
        locale={props.pageContext.locale}
        ref={teachersRef}
      />
      {isVirtualCampus(campusCode) || campusCode === 'rmt' ? (
        <DayAtIronhackRemote
          campus={campusName}
          course={t(`course-list:courseList.${courseCode}.title`)}
          courseCode={courseCode}
          ref={typicalDayRef}
        />
      ) : (
        <NewDayAtIronhack
          campus={campusName}
          course={fileI18n}
          courseCode={courseCode}
          ref={typicalDayRef}
        />
      )}
      {isFrenchVae && vaeData && (
        <Box
          as="section"
          bg="electricBlue.10"
          maxW={[null, null, null, '1296px']}
          mx="auto"
          p={[2, null, 4]}
          pt={[4, null, 8]}
          ref={vaeRef}
        >
          <Flex direction="row" gap={3}>
            {vaeData.companies.map(({ alt, gatsbyImageData, title }) => (
              <GatsbyImage
                alt={alt}
                image={gatsbyImageData}
                key={title}
                title={title}
              />
            ))}
          </Flex>
          <StructuredContent
            color="darkBlue.64"
            data={vaeData.content}
            textStyle="body4"
          />
        </Box>
      )}
      <Grid
        extend={LayoutIronhackInternational}
        mt={isFrenchWeb && 4}
        title={t('course-campus:courseCampus.whyShouldYouStudy.title', {
          campus: campusName,
          course: courseName,
        })}
      >
        {Object.values<{ title: string; description: string }>(
          t(whyShouldYouStudyKey, { returnObjects: true })
        ).map((whyShouldYouStudy) => (
          <Item key={uniqid()}>
            <Text2>{whyShouldYouStudy.title}</Text2>
            {whyShouldYouStudy.description}
          </Item>
        ))}
      </Grid>
      <StudentProjects course={fileI18n} courseCode={courseCode} />
      <CampusInformation>
        <Title1>
          {campusCode !== 'rmt'
            ? t('course-campus:courseCampus.whatSetsCampusApart.title', {
                campus: campusName,
              })
            : t('course-web-dev-rmt:web-rmt.whatSetsCampusApart.title')}
        </Title1>
        {Object.values<string[]>(
          t(
            `course-campus-${campusCode}:courseCampus-${campusCode}.whatSetsCampusApart`,
            { returnObjects: true }
          )
        ).map((text) => (
          <article key={uniqid()}>
            <Trans
              components={[<strong>Job Opportunities</strong>]}
              defaults={text}
              i18nKey={text}
            />
          </article>
        ))}
      </CampusInformation>
      {isInFranceCampusInFrench && (
        <Box pb={[3, null, 6]} px={[3, null, 10]}>
          <Text textStyle="title2">
            {t('course-list:courseList.navbar.faq')}
          </Text>
          {courseCode === 'web' && (
            <Text mt={3} textStyle="body3">
              {t('course-campus-par:courseCampus-par.faq.subtitle')}
            </Text>
          )}
          {courseCode !== 'cysec' && (
            <FrequentAskedQuestions category={`${courseCode}-par`} />
          )}
        </Box>
      )}
      <MoreQuestions showFrenchRegistration={isInFranceCampusInFrench} />
      <Footer page={pageName} />
    </Box>
  );
};

export default CampusCourse;
