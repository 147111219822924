import { updateDataLayer } from '../../lib/datalayer';

function emitApplyEvent(): void {
  updateDataLayer({
    event: 'gaEvent',
    eventCategory: 'interest',
    eventAction: 'click cta::apply',
    eventLabel: 'header::primary cta'
  });
}

function emitSeeCoursesEvent(): void {
  updateDataLayer({
    event: 'gaEvent',
    eventCategory: 'interest',
    eventAction: 'click button',
    eventLabel: 'header::secondary cta'
  });
}

export { emitApplyEvent, emitSeeCoursesEvent };
