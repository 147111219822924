import React, { forwardRef, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import uniqid from 'uniqid';
import { Tabs, TabList, Tab, TabPanel, TabPanels } from '@chakra-ui/react';
import _ from 'lodash/fp';

import Layout from '../../atoms/layout';
import Eyebrow from '../../atoms/titles/eyebrow';
import { Title2 } from '../../atoms/headers';
import { Text2, Text3, Text5 } from '../../atoms/body';
import {
  Timeline as TimelineStyle,
  Item,
  TimelineCircle,
  LayoutDayIronhack,
} from '../styles';
import DayAtIronhackProps from '../../interfaces/DayAtIronhack';

type TypicalDayGroup = {
  title?: string;
  days?: string;
  '01': TypicalDay;
  '02': TypicalDay;
  '03'?: TypicalDay;
  '04'?: TypicalDay;
};

type TypicalDay = {
  title: string;
  time: string;
  description: string;
  day?: string;
};

type TimelineProps = {
  typicalDayGroup: TypicalDay[][];
};

const formatTypicalDayGroup = (
  typicalDayGroup: TypicalDayGroup,
  fullTimeTitle?: string
): TypicalDay[] => {
  const day = fullTimeTitle || typicalDayGroup.title;

  return _.pipe(
    _.omit(['title', 'days']),
    _.set('01.day', day),
    _.values
  )(typicalDayGroup) as TypicalDay[];
};

const Timeline = ({ typicalDayGroup }: TimelineProps): React.ReactElement => (
  <TimelineStyle>
    {typicalDayGroup.map((days: TypicalDay[]) => (
      <section key={uniqid()}>
        <Text2>{days[0].day}&nbsp;</Text2>
        {days.map(({ time, description, title }: TypicalDay) => (
          <Item key={uniqid()}>
            <TimelineCircle />
            <Text5 as="p">{time}</Text5>
            <Text2>{title}</Text2>
            <Text3 as="p">{description}</Text3>
          </Item>
        ))}
      </section>
    ))}
  </TimelineStyle>
);

const DayAtIronhack = (props, ref) => {
  const { course, campus, courseCode } = props;
  const isCyberSecurityCourse = courseCode === 'cysec';

  const [format, setFormat] = useState(isCyberSecurityCourse ? 'ft' : 'pt');
  const { t } = useTranslation();
  const courseKeys: Record<string, string> = {
    ft: 'fullTime',
    pt: 'partTime',
  };

  const handleTabs = useCallback(() => {
    setFormat((format) => (format === 'ft' ? 'pt' : 'ft'));
  }, []);

  const typicalDays: Record<string, TypicalDay[][]> = {
    ft: [
      formatTypicalDayGroup(
        t(`${course}:${courseCode}.fullTime.typicalDay.weekDays`, {
          returnObjects: true,
        }),
        t('course-campus:courseCampus.typicalDay.fullTime')
      ),
    ],
    pt: [
      formatTypicalDayGroup(
        t(`${course}:${courseCode}.partTime.typicalDay.weekDay1`, {
          returnObjects: true,
        })
      ),
      formatTypicalDayGroup(
        t(`${course}:${courseCode}.partTime.typicalDay.weekDay2`, {
          returnObjects: true,
        })
      ),
      formatTypicalDayGroup(
        t(`${course}:${courseCode}.partTime.typicalDay.weekend`, {
          returnObjects: true,
        })
      ),
    ],
  };

  return (
    <Layout extend={LayoutDayIronhack} ref={ref}>
      <Eyebrow>{t('eyebrow:eyebrow.courseInformation')}</Eyebrow>
      <Title2 as="h2">
        {t(`${course}:${courseCode}.${courseKeys[format]}.typicalDay.title`, {
          campus,
        })}
      </Title2>
      <Text3 as="p">
        {t(
          `${course}:${courseCode}.${courseKeys[format]}.typicalDay.description`,
          {
            course,
          }
        )}
      </Text3>
      {!isCyberSecurityCourse && (
        <Tabs colorScheme="teal" isFitted onChange={handleTabs}>
          <TabList>
            <Tab _focus={{ boxShadow: 'none' }} fontSize={20} fontWeight="bold">
              {t(`upcoming-cohorts:upcomingCohorts.scheduleFormat.fullTime`)}
            </Tab>
            <Tab _focus={{ boxShadow: 'none' }} fontSize={20} fontWeight="bold">
              {t(`upcoming-cohorts:upcomingCohorts.scheduleFormat.partTime`)}
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel p={0}>
              <Timeline typicalDayGroup={typicalDays.ft} />
            </TabPanel>
            <TabPanel p={0}>
              <Timeline typicalDayGroup={typicalDays.pt} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      )}
    </Layout>
  );
};

export default forwardRef<HTMLDivElement, DayAtIronhackProps>(DayAtIronhack);
