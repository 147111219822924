import { graphql, useStaticQuery } from 'gatsby';
import _ from 'lodash/fp';

export function useCourses(): Array<any> {
  const { allDatoCmsCourse } = useStaticQuery(
    graphql`
      query {
        allDatoCmsCourse {
          edges {
            node {
              code
              webCode
              studentProjects {
                customData
                fluid(
                  maxWidth: 1440
                  imgixParams: { fm: "jpg", auto: "compress" }
                ) {
                  ...GatsbyDatoCmsFluid
                }
              }
            }
          }
        }
      }
    `
  );

  return _.flow(
    _.getOr([], 'edges'),
    _.filter('node.webCode'),
    _.uniqBy('node.webCode'),
    _.groupBy('node.webCode'),
    _.mapValues(list => {
      const course = _.getOr({}, '0.node', list);
      course.studentProjects = _.flow(
        _.sortBy('customData.order'),
        _.map(project => _.getOr({}, 'fluid', project, 'fluid'))
      )(course.studentProjects);
      return course;
    })
  )(allDatoCmsCourse);
}
