import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const CareerServicesIcon = createIcon({
  displayName: 'CareerServicesIcon',
  path: (
    <>
      <circle cx="53" cy="53" fill="#EEEDFF" r="53" />
      <path
        d="M25 51c0 17 14 31 31 31V20c-17 0-31 14-31 31Z"
        fill="url(#career-services-icon-a)"
      />
      <mask fill="#fff" id="career-services-icon-c">
        <path
          clipRule="evenodd"
          d="M56 68a17 17 0 0 1 0-34"
          fillRule="evenodd"
        />
      </mask>
      <path
        d="M56 83c-18 0-32-14-32-32h30l2 2v30ZM24 51c0-18 14-32 32-32v30l-2 2H24Z"
        fill="url(#career-services-icon-b)"
        mask="url(#career-services-icon-c)"
      />
      <mask fill="#fff" id="career-services-icon-e">
        <path
          clipRule="evenodd"
          d="M56 68a17 17 0 0 0 0-34"
          fillRule="evenodd"
        />
      </mask>
      <path
        d="M56 83c18 0 32-14 32-32H58l-2 2v30Zm32-32c0-18-14-32-32-32v30l2 2h30Z"
        fill="url(#career-services-icon-d)"
        mask="url(#career-services-icon-e)"
      />
      <path d="M59 51a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" fill="#F76F8F" />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="career-services-icon-a"
          x1="40.6"
          x2="40.6"
          y1="19.9"
          y2="81.7"
        >
          <stop stopColor="#68E0D0" />
          <stop offset="1" stopColor="#30C7F9" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="career-services-icon-b"
          x1="54.1"
          x2="36.3"
          y1="66.5"
          y2="64.4"
        >
          <stop stopColor="#F79768" />
          <stop offset="1" stopColor="#FC6EA9" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="career-services-icon-d"
          x1="58"
          x2="75.8"
          y1="66.5"
          y2="64.4"
        >
          <stop stopColor="#827FFF" />
          <stop offset="1" stopColor="#ADB0FF" />
        </linearGradient>
      </defs>
    </>
  ),
  viewBox: '0 0 106 106',
});

export default CareerServicesIcon;
