import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const HTML = createIcon({
  displayName: 'HTMLIcon',
  viewBox: '0 0 27 30',
  path: (
    <>
      <path d="M2.2 26.9 0 0h26.2L24 26.9l-10.7 3-11-3Z" fill="#E34F26" />
      <path d="M13.2 27.4V2.6H24l-2 22.2-8.8 2.6Z" fill="#EF652A" />
      <path
        d="m21 8.8.3-3.3H4.7l1 10H17l-.4 4.3-3.7 1-3.7-1-.2-2.6H5.8l.4 5.2 6.8 1.9 6.8-1.9 1-10.2H8.6l-.4-3.4H21Z"
        fill="#fff"
      />
    </>
  ),
});

export default HTML;
