import React from 'react';
import { useTranslation } from 'react-i18next';

import Layout from '../atoms/layout';
import { Title1 } from '../atoms/headers';
import { Process, CardProcess, LayoutApplicationProcess } from './styles';
import { Text1 } from '../atoms/body';
import config from '../config';

const ApplicationProcess: React.SFC<any> = (props: {
  course: string;
  campus: string;
  title?: string;
}) => {
  const { course, campus, title } = props;
  const { t } = useTranslation();
  const isParis = campus === 'Paris';

  return (
    <>
      <Layout extend={LayoutApplicationProcess}>
        <Title1>
          {title
            ? title
            : t('course-campus:courseCampus.applicationProcess.title', {
                course,
                campus,
              })}
        </Title1>
      </Layout>
      <Process>
        <CardProcess background={config.palette.applicationProcess.green01}>
          <Title1 as="b">01</Title1>
          <Text1 as="h3">
            {t('course-campus:courseCampus.applicationProcess.steps.01.title')}
          </Text1>
          {t(
            'course-campus:courseCampus.applicationProcess.steps.01.description',
            {
              course,
            }
          )}
        </CardProcess>
        <CardProcess background={config.palette.applicationProcess.green02}>
          <Title1 as="b">02</Title1>
          <Text1 as="h3">
            {t('course-campus:courseCampus.applicationProcess.steps.02.title')}
            {isParis &&
              ` - ${t(
                'course-campus-par:courseCampus-par.applicationProcess.language'
              )}`}
          </Text1>
          {t(
            'course-campus:courseCampus.applicationProcess.steps.02.description',
            {
              course,
              campus,
            }
          )}
        </CardProcess>
        <CardProcess background={config.palette.applicationProcess.green03}>
          <Title1 as="b">03</Title1>
          <Text1 as="h3">
            {t('course-campus:courseCampus.applicationProcess.steps.03.title')}
            {isParis &&
              ` - ${t(
                'course-campus-par:courseCampus-par.applicationProcess.language'
              )}`}
          </Text1>
          {t(
            'course-campus:courseCampus.applicationProcess.steps.03.description',
            {
              course,
            }
          )}
        </CardProcess>
        <CardProcess background={config.palette.applicationProcess.green04}>
          <Title1 as="b">04</Title1>
          <Text1 as="h3">
            {t('course-campus:courseCampus.applicationProcess.steps.04.title')}
          </Text1>
          {t(
            'course-campus:courseCampus.applicationProcess.steps.04.description',
            {
              course,
              campus,
            }
          )}
        </CardProcess>
        <CardProcess background={config.palette.applicationProcess.green05}>
          <Title1 as="b">05</Title1>
          <Text1 as="h3">
            {t('course-campus:courseCampus.applicationProcess.steps.05.title')}
          </Text1>
          {t(
            'course-campus:courseCampus.applicationProcess.steps.05.description'
          )}
        </CardProcess>
      </Process>
    </>
  );
};

export default ApplicationProcess;
