import {
  Text,
  OrderedList,
  UnorderedList,
  Link,
  ListItem,
  Box,
} from '@chakra-ui/react';
import { StructuredText, renderRule } from 'react-datocms';
import {
  isHeading,
  isParagraph,
  isLink,
  isList,
  isListItem,
} from 'datocms-structured-text-utils';
import React from 'react';

import type { TextProps } from '@chakra-ui/react';
import type { StructuredTextGraphQlResponse } from 'react-datocms';
import type {
  DatoCustomBlock,
  DatoExternalLinkButtonBlock,
  DatoInternalLinkButtonBlock,
} from '../../lib/datocms';

type Props = TextProps & { data: StructuredTextGraphQlResponse };

export const StructuredContent = (props: Props): React.ReactElement => {
  const { data, ...textProps } = props;
  const renderBlock = (record: DatoCustomBlock): React.ReactElement => {
    switch (record.__typename) {
      case 'ImageBlockRecord':
        return <div>{'image'}</div>;
      case 'InternalLinkButtonRecord': {
        const { text, link } = record as DatoInternalLinkButtonBlock;
        return (
          <div>
            {text}
            {' - '}
            {link.pageUrl}
          </div>
        );
      }
      case 'ExternalLinkButtonRecord': {
        const { text, link } = record as DatoExternalLinkButtonBlock;
        return (
          <div>
            {text}
            {' - '}
            {link}
          </div>
        );
      }
      default:
        return null;
    }
  };

  return (
    <StructuredText
      customRules={[
        renderRule(isLink, ({ key, children, node, ...props }) => (
          <Link as="a" href={node.url} key={key} rel="noopener" target="_blank">
            {children}
          </Link>
        )),
        renderRule(isParagraph, ({ children, key }) => (
          <Text key={key} {...textProps}>
            {children}
          </Text>
        )),
        // renderRule(isBlockquote, ({ node, children, key }) => {
        //   const props = { node, children, key };
        //   return <BlockQuote {...props} />;
        // }),
        // renderRule(isCode, ({ node, key }) => (
        //   <CodeHilight
        //     code={node.code}
        //     key={key}
        //     language={node.language || 'js'}
        //     plugins={['line-numbers']}
        //   />
        // )),
        renderRule(isHeading, ({ node, children, key }) => (
          <Text
            as={`h${node.level}`}
            key={key}
            pb={4}
            pt={6}
            textStyle="title2"
          >
            {children}
          </Text>
        )),
        renderRule(isList, ({ node, children, key }) => (
          <Box key={key}>
            {node?.style === 'numbered' ? (
              <OrderedList mb={3} spacing={0}>
                {children}
              </OrderedList>
            ) : (
              <UnorderedList mb={3} spacing={0}>
                {children}
              </UnorderedList>
            )}
          </Box>
        )),
        renderRule(isListItem, ({ node, children, key }) => (
          <ListItem key={key}>{children}</ListItem>
        )),
      ]}
      data={data}
      renderBlock={({
        record,
      }: {
        record: DatoCustomBlock;
      }): ReturnType<typeof renderBlock> => renderBlock(record)}
    />
  );
};
