import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const Python = createIcon({
  displayName: 'Python',
  path: (
    <>
      <path
        d="M14.93 0C7.3 0 7.78 3.33 7.78 3.33v3.42h7.29v1.03H4.9S.02 7.22.02 14.91c0 7.7 4.26 7.43 4.26 7.43h2.54v-3.57s-.14-4.26 4.2-4.26h7.21s4.06.06 4.06-3.92v-6.6S22.9.02 14.93.02Zm-4.01 2.31a1.3 1.3 0 1 1 0 2.62 1.3 1.3 0 0 1 0-2.62Z"
        fill="#36B2B2"
      />
      <path
        d="M15.15 29.9c7.63 0 7.15-3.31 7.15-3.31v-3.43H15v-1.03h10.17s4.88.56 4.88-7.14c0-7.7-4.26-7.42-4.26-7.42h-2.54v3.57s.14 4.26-4.2 4.26h-7.21s-4.06-.07-4.06 3.92v6.59s-.61 3.99 7.36 3.99Zm4.01-2.3a1.3 1.3 0 1 1 0-2.63 1.31 1.31 0 1 1 0 2.62Z"
        fill="#FFD26F"
      />
    </>
  ),
  viewBox: '0 0 31 30',
});

export default Python;
