import React, { useContext } from 'react';
import { withTranslation } from 'react-i18next';
import CourseFormatContext from '../../context/CourseFormat';
import Layout from '../atoms/layout';
import Eyebrow from '../atoms/titles/eyebrow';
import { Title2 } from '../atoms/headers';
import { Text2, Text3, Text5 } from '../atoms/body';
import { Timeline, Item, TimelineCircle, LayoutDayIronhack } from './styles';
import DayAtIronhackProps from '../interfaces/DayAtIronhack';
import classNames from 'classnames';

interface TypeTime {
  time: string;
  description: string;
  title: string;
}

const DayAtIronhackRemote: React.SFC<any> = (props: DayAtIronhackProps) => {
  const ctxFormat = useContext(CourseFormatContext);
  const { campus, course, courseCode, t } = props;
  const scheduleOption = ctxFormat.format;
  const fullTimeSchedule = Object.entries(
    t(`course-web-dev-rmt:web-rmt.typicalDay.fullTime`, {
      returnObjects: true
    })
  );
  const partTimeScheduleOne = Object.entries(
    t(`course-web-dev-rmt:web-rmt.typicalDay.partTime.weekDay`, {
      returnObjects: true
    })
  );
  const partTimeScheduleTwo = Object.entries(
    t(`course-web-dev-rmt:web-rmt.typicalDay.partTime.weekendDay`, {
      returnObjects: true
    })
  );
  const sortFunction = (schedule: Array<Array<TypeTime>>): Array<TypeTime> => {
    const sortedSchedule = schedule.sort(
      (
        timeBlockA: Array<string | TypeTime>,
        timeBlockB: Array<string | TypeTime>
      ) => timeBlockA[0] - timeBlockB[0]
    );
    return sortedSchedule.map(
      (timeBlockArray: Array<TypeTime>) => timeBlockArray[1]
    );
  };

  const dayOption: Array<TypeTime> =
    scheduleOption === 'pt' ? partTimeScheduleOne : fullTimeSchedule;
  const classPtWeekday = scheduleOption === 'pt' && 'pt-weekday';
  const createContent = (
    day: Array<TypeTime>,
    optionalClass?: string
  ): Array<JSX.Element> => {
    return day.map(({ time, description, title }, index: number) => (
      <Item
        className={classNames(
          { [scheduleOption]: scheduleOption.length > 0 },
          optionalClass
        )}
        key={`item-${index}`}
      >
        <TimelineCircle key={`circle-${index}`} />
        <Text5 key={`text5-${index}`} as="p">
          {time}
        </Text5>
        <Text2 key={`text2-${index}`}>{title}</Text2>
        <Text3 key={`text3-${index}`} as="p">
          {description}
        </Text3>
      </Item>
    ));
  };

  const isCyberSecurityCourse = courseCode === 'cysec';
  const translationKey = isCyberSecurityCourse ? 'cysec-rmt' : 'web-rmt';

  return (
    <>
      <Layout extend={LayoutDayIronhack}>
        <Eyebrow>{t('eyebrow:eyebrow.courseInformation')}</Eyebrow>
        <Title2 as="h2">
          {t(`course-web-dev-rmt:${translationKey}.typicalDay.title`, {
            campus,
            course
          })}
        </Title2>
        <Text3 as="p">
          {t(`course-web-dev-rmt:${translationKey}.typicalDay.description`, {
            course
          })}
        </Text3>
      </Layout>
      {!isCyberSecurityCourse && (
        <Timeline>
          <section>
            {ctxFormat.format === 'ft' ? (
              <Text2>{t(`course-web-dev-rmt:web-rmt.typicalDay.monFri`)}</Text2>
            ) : (
              <Text2>
                {t(`course-web-dev-rmt:web-rmt.typicalDay.weekDay`)}
              </Text2>
            )}
            {createContent(sortFunction(dayOption), classPtWeekday)}
          </section>
        </Timeline>
      )}
      {ctxFormat.format === 'pt' && (
        <Timeline>
          <section>
            <Text2>
              {t(`course-web-dev-rmt:web-rmt.typicalDay.weekendDay`)}
            </Text2>
            {createContent(sortFunction(partTimeScheduleTwo))}
          </section>
        </Timeline>
      )}
    </>
  );
};

export default withTranslation()(DayAtIronhackRemote);
