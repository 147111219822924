import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const DesignThinking = createIcon({
  displayName: 'DesignThinking',
  path: (
    <>
      <path
        d="M2 10.64C2 5.84 6.03 2 11 2s9 3.84 9 8.64c0 3.72-4.02 7.08-5.14 9.36v6H7.14v-6C6.02 17.72 2 14.36 2 10.64Z"
        stroke="url(#design-thiking-icon-a)"
        strokeWidth="4"
      />
      <path d="M5 31h12" stroke="url(#design-thiking-icon-b)" strokeWidth="2" />
      <path d="M7 35h8" stroke="#E57373" strokeWidth="2" />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="design-thiking-icon-a"
          x1="11"
          x2="11"
          y1="2"
          y2="26"
        >
          <stop stopColor="#68E0D0" />
          <stop offset="1" stopColor="#30C7F9" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="design-thiking-icon-b"
          x1="8.02"
          x2="8.01"
          y1="32.23"
          y2="33.01"
        >
          <stop stopColor="#FFA478" />
          <stop offset="1" stopColor="#FB67AE" />
        </linearGradient>
      </defs>
    </>
  ),
  viewBox: '0 0 22 36',
});

export default DesignThinking;
